@font-face {
    font-family: 'Inter';
    src: url('../assets/Inter.ttf') format('truetype');
}
#springbok_chatbot_widget_shadow {
    position: fixed !important;
    z-index: 2000000 !important;
    all: initial;
}
#springbok_chatbot_widget_shadow * {
    font-family: 'Inter', sans-serif;
}
#springbok_chatbot_widget_shadow * {
    box-sizing: content-box;
}
#springbok_chatbot_widget_shadow ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}
#springbok_chatbot_widget_shadow ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.031372549);
    border-radius: 10px;
}
#springbok_chatbot_widget_shadow ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1254901961);
    border-radius: 10px;
}
#springbok_chatbot_widget_shadow ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.1882352941);
}
#springbok_chatbot_widget_shadow :root {
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.1254901961);
}
#springbok_chatbot_widget_shadow button:hover {
    transition: all 0.2s ease-in-out;
    filter: brightness(110%);
}
#springbok_chatbot_widget_shadow .hidden {
    display: none;
}
#springbok_chatbot_widget_shadow .flexC {
    display: flex;
    flex-direction: column;
}
#springbok_chatbot_widget_shadow .flexR {
    display: flex;
    flex-direction: row;
}
#springbok_chatbot_widget_shadow .inactiveWidgetPopup {
    position: fixed;
    font-size: 14px !important;
    bottom: 85px;
    right: 85px;
    transform-origin: bottom right;
    width: 200px;
    padding: 10px 18px 10px 10px;
    border-radius: 10px 10px 0px 10px;
    border: 3px solid;
    transition: all 250ms linear 2s;
    text-align: left;
    cursor: pointer;
    animation: initial-fade-in 1s ease-in 0s 1, pulse 4s linear 5s infinite;
}
#springbok_chatbot_widget_shadow .inactiveWidgetPopup .inactiveWidgetPopupClose {
    position: absolute;
    top: 3px;
    right: 3px;
    width: 16px;
    height: 16px;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    cursor: pointer;
}
@media only screen and (max-width: 720px) {
    #springbok_chatbot_widget_shadow .inactiveWidgetPopup {
        position: fixed;
        font-size: 14px !important;
        bottom: 61px;
        right: 61px;
        width: 200px;
        padding: 10px 18px 10px 10px;
        border-radius: 10px 10px 0px 10px;
        border: 3px solid;
    }
    #springbok_chatbot_widget_shadow .inactiveWidgetPopup img {
        height: 100%;
        width: 100%;
        border-radius: 15px;
    }
    #springbok_chatbot_widget_shadow .inactiveWidgetPopup img:hover {
        filter: brightness(150%);
        cursor: pointer;
    }
}
#springbok_chatbot_widget_shadow .inactiveWidgetPopup.show {
    display: flex;
}
#springbok_chatbot_widget_shadow .inactiveWidgetPopup.hide {
    display: none;
}
#springbok_chatbot_widget_shadow .inactiveWidgetButton {
    position: fixed;
    bottom: 10px;
    right: 10px;
    padding: 0px;
    height: 80px;
    width: 80px;
    border-radius: 100%;
    animation: initial-fade-in 1s ease-in 0s 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
#springbok_chatbot_widget_shadow .inactiveWidgetButton img {
    height: 82px;
    width: 82px;
    border-radius: 100%;
}
#springbok_chatbot_widget_shadow .inactiveWidgetButton img:hover {
    transition: all 0.2s ease-in-out;
    filter: brightness(130%);
    cursor: pointer;
}
@media only screen and (max-width: 720px) {
    #springbok_chatbot_widget_shadow .inactiveWidgetButton {
        position: fixed;
        bottom: 5px;
        right: 5px;
        padding: 0px;
        height: 60px;
        width: 60px;
        border-radius: 100px;
        border: none;
    }
    #springbok_chatbot_widget_shadow .inactiveWidgetButton img {
        height: 100%;
        width: 100%;
        border-radius: 100px;
    }
    #springbok_chatbot_widget_shadow .inactiveWidgetButton img:hover {
        transition: all 0.2s ease-in-out;
        filter: brightness(130%);
        cursor: pointer;
    }
}
#springbok_chatbot_widget_shadow .scrollButton {
    visibility: collapse;
}
#springbok_chatbot_widget_shadow .activeWidget.fullScreen {
    min-width: 50vw;
    min-height: 80vh;
    transition: all 0.5s ease-in-out;
}
#springbok_chatbot_widget_shadow .activeWidget.mobile {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100dvh;
    border-radius: 0px;
}
#springbok_chatbot_widget_shadow .activeWidget {
    position: fixed;
    display: flex;
    flex-direction: column;
    min-width: 0px;
    min-height: 0px;
    bottom: 10px;
    border: none;
    right: 10px;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.16), 0 0 5px 0 rgba(0, 0, 0, 0.5882352941);
    border-radius: 20px;
    animation: initial-fade-in 0.5s ease-in 0s 1;
    transition: all 0.5s ease-in-out;
}
#springbok_chatbot_widget_shadow .activeWidget iframe {
    max-width: 95%;
    border: none;
    transition: all 0.5s ease-in-out;
}
#springbok_chatbot_widget_shadow .activeWidget .titleHolder {
    position: sticky;
    height: 20px;
    top: 0px;
    z-index: 2;
    align-items: center;
    padding-top: 4px;
    padding-bottom: 4px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0 3px 5px -3px rgba(0, 0, 0, 0.08);
    animation: initial-fade-in 0.5s ease-in 0s 1;
}
#springbok_chatbot_widget_shadow .activeWidget .titleHolder .backButton {
    width: 18px;
    height: 18px;
    padding: 3px 12px 3px 12px;
    max-height: 32px;
    cursor: pointer;
    animation: initial-fade-in 0.5s ease-in 0s 1;
}
#springbok_chatbot_widget_shadow .activeWidget .titleHolder .title {
    margin-left: 5px;
    font-size: 16px !important;
    font-weight: 600;
    animation: initial-fade-in 0.5s ease-in 0s 1;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut {
    animation: fade-out 0.5s ease-out 0s 1;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay {
    animation: initial-fade-in 0.5s ease-in 0s 1;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut {
    z-index: 9999;
    position: absolute;
    top: 56px;
    right: 0;
    height: calc(100% - 56px);
    width: 100%;
    border-radius: 0px 0px 20px 20px;
    background-color: rgba(83, 83, 83, 0.9);
    transition: all 0.5s ease-in-out;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget.widgetMenu,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget.widgetMenu {
    animation: initial-fade-in 0.5s ease-in 0s 1;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget {
    justify-content: space-around;
    text-align: center;
    transition: all 0.5s ease-in-out;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 80%;
    transition: all 0.5s ease-in-out;
    font-weight: 300;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedbackButtons,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedbackButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 100%;
    margin: 20px 20px;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedbackButtons button,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedbackButtons button {
    border: none;
    border-radius: 5px;
    cursor: pointer;
    max-width: 120px;
    padding: 15px 20px;
    margin: 0px 7px;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form {
    animation: initial-fade-in 0.5s ease-in 0s 1;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form-fade-out,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form-fade-out {
    animation: fade-out 0.5s ease-out 0s 1;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form-fade-out,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form-fade-out {
    z-index: 10000;
    background-color: #fff;
    position: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 0px;
    animation: initial-fade-in 0.5s ease-in 0s 1;
    transition: all 0.5s ease-in-out;
    cursor: default;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form .feedbackButtons,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form-fade-out .feedbackButtons,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form .feedbackButtons,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form-fade-out .feedbackButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form h3,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form-fade-out h3,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form h3,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form-fade-out h3 {
    margin: 0;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form button,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form-fade-out button,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form button,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form-fade-out button {
    border: none;
    border-radius: 5px;
    cursor: pointer;
    max-width: 120px;
    padding: 15px 20px;
    margin: 0px 20px;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form .feedback-emojis,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form-fade-out .feedback-emojis,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form .feedback-emojis,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form-fade-out .feedback-emojis {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 300px;
    font-size: x-large;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form .feedback-emojis span,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form-fade-out .feedback-emojis span,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form .feedback-emojis span,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form-fade-out .feedback-emojis span {
    border: none;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form .feedback-emojis span div,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form-fade-out .feedback-emojis span div,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form .feedback-emojis span div,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form-fade-out .feedback-emojis span div {
    font-size: 14px;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form .feedback-emojis span:hover,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form-fade-out .feedback-emojis span:hover,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form .feedback-emojis span:hover,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form-fade-out .feedback-emojis span:hover {
    transition: all 0.2s ease-in-out;
    filter: brightness(120%);
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form .feedback-text,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form-fade-out .feedback-text,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form .feedback-text,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form-fade-out .feedback-text {
    flex-direction: column;
    text-align: center;
    animation: initial-fade-in 0.5s ease-in 0s 1;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form .feedback-text textarea,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form-fade-out .feedback-text textarea,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form .feedback-text textarea,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form-fade-out .feedback-text textarea {
    background-color: rgba(0, 0, 0, 0);
    resize: none;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
}
@media only screen and (max-width: 720px) {
    #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form .feedback-text textarea,
    #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form-fade-out .feedback-text textarea,
    #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form .feedback-text textarea,
    #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form-fade-out .feedback-text textarea {
        font-size: 16px !important;
    }
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut {
    animation: scrollOut 0.5s ease-out 0s 1;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu {
    animation: scrollIn 0.5s ease-in 0s 1;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut {
    z-index: 10000;
    padding: 8px 15px 0px 15px;
    width: calc(100% - 30px);
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0px;
    right: 0px;
    border-radius: 0px 0px 20px 20px;
    font-size: 14px;
    font-weight: 600;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu div,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut div,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu div,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut div {
    padding: 5px;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form {
    animation: initial-fade-in 0.5s ease-in 0s 1;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form-fade-out,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form-fade-out,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form-fade-out,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form-fade-out {
    animation: fade-out 0.5s ease-out 0s 1;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form-fade-out,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form-fade-out,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form-fade-out,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form-fade-out {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 0px;
    transition: all 0.5s ease-in-out;
    font-weight: 300;
    cursor: default;
}
@media only screen and (max-width: 720px) {
    #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form,
    #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form-fade-out,
    #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form,
    #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form-fade-out,
    #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form,
    #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form-fade-out,
    #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form,
    #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form-fade-out {
        padding-left: 20px;
    }
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form h3,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form-fade-out h3,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form h3,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form-fade-out h3,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form h3,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form-fade-out h3,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form h3,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form-fade-out h3 {
    margin: 0;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form button,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form-fade-out button,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form button,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form-fade-out button,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form button,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form-fade-out button,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form button,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form-fade-out button {
    border: none;
    border-radius: 5px;
    cursor: pointer;
    max-width: 120px;
    padding: 15px 20px;
    margin: 0px 20px;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form .feedback-emojis,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form-fade-out .feedback-emojis,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form .feedback-emojis,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form-fade-out .feedback-emojis,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form .feedback-emojis,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form-fade-out .feedback-emojis,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form .feedback-emojis,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form-fade-out .feedback-emojis {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 300px;
    font-size: x-large;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form .feedback-emojis span,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form-fade-out .feedback-emojis span,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form .feedback-emojis span,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form-fade-out .feedback-emojis span,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form .feedback-emojis span,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form-fade-out .feedback-emojis span,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form .feedback-emojis span,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form-fade-out .feedback-emojis span {
    border: none;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form .feedback-emojis span div,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form-fade-out .feedback-emojis span div,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form .feedback-emojis span div,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form-fade-out .feedback-emojis span div,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form .feedback-emojis span div,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form-fade-out .feedback-emojis span div,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form .feedback-emojis span div,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form-fade-out .feedback-emojis span div {
    font-size: 14px;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form .feedback-emojis span:hover,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form-fade-out .feedback-emojis span:hover,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form .feedback-emojis span:hover,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form-fade-out .feedback-emojis span:hover,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form .feedback-emojis span:hover,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form-fade-out .feedback-emojis span:hover,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form .feedback-emojis span:hover,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form-fade-out .feedback-emojis span:hover {
    transition: all 0.2s ease-in-out;
    filter: brightness(120%);
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form .feedback-text,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form-fade-out .feedback-text,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form .feedback-text,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form-fade-out .feedback-text,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form .feedback-text,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form-fade-out .feedback-text,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form .feedback-text,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form-fade-out .feedback-text {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: space-between;
    animation: initial-fade-in 0.5s ease-in 0s 1;
    transition: all 0.5s ease-in-out;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form .feedback-text textarea,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form-fade-out .feedback-text textarea,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form .feedback-text textarea,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form-fade-out .feedback-text textarea,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form .feedback-text textarea,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form-fade-out .feedback-text textarea,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form .feedback-text textarea,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form-fade-out .feedback-text textarea {
    background-color: rgba(0, 0, 0, 0);
    resize: none;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
}
@media only screen and (max-width: 720px) {
    #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form .feedback-text textarea,
    #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form-fade-out .feedback-text textarea,
    #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form .feedback-text textarea,
    #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form-fade-out .feedback-text textarea,
    #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form .feedback-text textarea,
    #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form-fade-out .feedback-text textarea,
    #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form .feedback-text textarea,
    #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form-fade-out .feedback-text textarea {
        font-size: 16px !important;
    }
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuHeader,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuHeader,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuHeader,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuHeader {
    border-bottom: 2px solid rgba(0, 0, 0, 0.031372549);
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuHeader .widgetMenuCloseBtn,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuHeader .widgetMenuCloseBtn,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuHeader .widgetMenuCloseBtn,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuHeader .widgetMenuCloseBtn {
    border: none;
    background: none;
    position: absolute;
    right: 15px;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuHeader .widgetMenuCloseBtn :hover,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuHeader .widgetMenuCloseBtn :hover,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuHeader .widgetMenuCloseBtn :hover,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuHeader .widgetMenuCloseBtn :hover {
    cursor: pointer;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuHeader svg,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuHeader svg,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuHeader svg,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuHeader svg {
    width: 20px;
    height: 20px;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuOptions,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuOptions,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuOptions,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuOptions {
    margin-top: 4px;
    margin-bottom: 4px;
    overflow-y: auto;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuOptions div,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuOptions div,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuOptions div,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuOptions div {
    display: flex;
    flex-direction: row;
    align-items: center;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuOptions .widgetMenuOptImg,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuOptions .widgetMenuOptImg,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuOptions .widgetMenuOptImg,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuOptions .widgetMenuOptImg {
    margin-right: 15px;
    height: 20px;
    width: 20px;
    cursor: pointer;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuOptions input,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuOptions input,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuOptions input,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuOptions input {
    margin-left: 35px;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuOptions .sendEmailBtn,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuOptions .sendEmailBtn,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuOptions .sendEmailBtn,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuOptions .sendEmailBtn {
    margin-left: 5px;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuOptions .widgetMenuOpt,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuOptions .widgetMenuOpt,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuOptions .widgetMenuOpt,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuOptions .widgetMenuOpt {
    cursor: pointer;
    margin: 5px 0px 5px 0px;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuOptions .widgetMenuOpt:hover,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuOptions .widgetMenuOpt:hover,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuOptions .widgetMenuOpt:hover,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuOptions .widgetMenuOpt:hover {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.031372549);
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuEmailSent,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuEmailSent,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuEmailSent,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuEmailSent {
    font-style: italic;
    font-weight: normal;
    padding-left: 40px;
    animation: initial-fade-in 0.5s ease-in 0s 1;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuEmailForm,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuEmailForm,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuEmailForm,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuEmailForm {
    animation: initial-fade-in 0.5s ease-in 0s 1;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuEmailForm input,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuEmailForm input,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuEmailForm input,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuEmailForm input {
    height: 20px;
    font-size: 16px;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuEmailForm button,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuEmailForm button,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuEmailForm button,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuEmailForm button {
    width: 20px;
    height: 20px;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    cursor: pointer;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuFooter,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuFooter,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuFooter,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuFooter {
    border-top: 2px solid rgba(0, 0, 0, 0.031372549);
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    font-weight: bold;
    font-size: 12px;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuFooter a,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuFooter a,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuFooter a,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuFooter a {
    font-weight: normal;
    color: #000;
    cursor: pointer;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuFooter a :visited,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuFooter a :visited,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuFooter a :visited,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuFooter a :visited {
    color: #000;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuFooter a :hover,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuFooter a :hover,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuFooter a :hover,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuFooter a :hover {
    color: #000;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuFooter .widgetMenuPB,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuFooter .widgetMenuPB,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuFooter .widgetMenuPB,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuFooter .widgetMenuPB {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuFooter .widgetMenuPB img,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuFooter .widgetMenuPB img,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuFooter .widgetMenuPB img,
#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuFooter .widgetMenuPB img {
    max-height: 40px;
    margin-left: 5px;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetHeader.mobile {
    border-radius: 0px;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    border-radius: 20px 20px 0 0;
    padding: 0px 20px;
    font-size: 20px;
    z-index: 11;
    transition: all 0.5s ease-in-out;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetHeader img {
    max-height: 40px;
    max-width: 40px;
    border-radius: 30px;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetHeader .title {
    font-size: 18px !important;
    font-weight: bold;
    overflow-wrap: normal;
    text-align: center;
    color: #fff;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetHeader .buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetHeader button {
    background-color: #05c3cf;
    border: none;
    border-radius: 5px;
    margin: 3px;
    padding: 0;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetHeader button:hover {
    transition: all 0.2s ease-in-out;
    filter: brightness(120%);
    cursor: pointer;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetHeader .customHeaderButton {
    height: 30px;
    width: 50px;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetHeader .buttonUnavailable:hover {
    cursor: wait;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetHeader .ellipsis {
    height: 24px;
    width: 24px;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetHeader .refreshChat,
#springbok_chatbot_widget_shadow .activeWidget .widgetHeader .closeButton,
#springbok_chatbot_widget_shadow .activeWidget .widgetHeader .minimiseButton,
#springbok_chatbot_widget_shadow .activeWidget .widgetHeader .ellipsis {
    margin: 1px 0px 1px 4px;
    animation: initial-fade-in 0.5s ease-in 0s 1;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetHeader .refreshChat:hover,
#springbok_chatbot_widget_shadow .activeWidget .widgetHeader .closeButton:hover,
#springbok_chatbot_widget_shadow .activeWidget .widgetHeader .minimiseButton:hover,
#springbok_chatbot_widget_shadow .activeWidget .widgetHeader .ellipsis:hover {
    transition: all 0.2s ease-in-out;
    filter: brightness(120%);
    cursor: pointer;
}
#springbok_chatbot_widget_shadow .activeWidget .widgetHeader .refreshChat,
#springbok_chatbot_widget_shadow .activeWidget .widgetHeader .minimiseButton,
#springbok_chatbot_widget_shadow .activeWidget .widgetHeader .closeButton {
    height: 22px;
    width: 22px;
}
#springbok_chatbot_widget_shadow .activeWidget .coverPageHolder {
    z-index: 10;
    padding: 15px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    border-radius: 0px 0px 20px 20px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease-in-out;
}
#springbok_chatbot_widget_shadow .activeWidget .coverPageHolder .coverPageHeader {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
#springbok_chatbot_widget_shadow .activeWidget .coverPageHolder .coverPageText {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px;
    text-align: center;
    max-width: 400px;
    font-size: 14px !important;
}
#springbok_chatbot_widget_shadow .activeWidget .coverPageHolder .coverPageSubheader {
    display: flex;
    flex-direction: row;
    padding-left: 10%;
    margin-top: 10%;
    font-size: 14px !important;
}
#springbok_chatbot_widget_shadow .activeWidget .coverPageHolder .coverPageDropdown {
    margin: 5px 10% 10% 10%;
    width: 80%;
    display: flex;
    flex-direction: column;
    font-size: 14px !important;
}
#springbok_chatbot_widget_shadow .activeWidget .coverPageHolder .coverPageButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
#springbok_chatbot_widget_shadow .activeWidget .coverPageHolder .coverPageButtonContainer .coverPageSubmitButton {
    padding: 7px;
    padding-left: 50px;
    padding-right: 50px;
    margin: 10px;
    border: none;
    border-radius: 10px;
    font-size: 14px !important;
}
#springbok_chatbot_widget_shadow .activeWidget .coverPageHolder .coverPageButtonContainer .coverPageSubmitButton:hover {
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    filter: brightness(110%);
}
#springbok_chatbot_widget_shadow .activeWidget .coverPageHolder .coverPageImgHolder {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
#springbok_chatbot_widget_shadow .activeWidget .coverPageHolder .coverPageImgHolder .coverPageImg {
    margin: 10px;
    max-width: 200px;
    min-width: 100px;
}
#springbok_chatbot_widget_shadow .activeWidget .userInput {
    animation: initial-fade-in 0.5s ease-in 0s 1;
    transition: all 0.5s ease-in-out;
}
#springbok_chatbot_widget_shadow .activeWidget .userInput .inputArea {
    flex-wrap: nowrap;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.1254901961);
    border-radius: 10px;
    width: 100%;
    margin: 4px 10px 8px 10px;
}
#springbok_chatbot_widget_shadow .activeWidget .userInput .inputArea textarea {
    background-color: rgba(0, 0, 0, 0);
    max-height: 26px;
    padding: 5px 0px;
    margin: 5px 40px 5px 10px;
    resize: none;
    border: none;
    outline: none;
    box-shadow: none;
    flex: 1;
}
@media only screen and (max-width: 720px) {
    #springbok_chatbot_widget_shadow .activeWidget .userInput .inputArea textarea {
        font-size: 16px !important;
    }
}
#springbok_chatbot_widget_shadow .activeWidget .userInput .inputArea svg {
    position: absolute;
    right: 10px;
    bottom: 7px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    padding: 15px 10px;
}
#springbok_chatbot_widget_shadow .activeWidget .main {
    overflow-x: hidden;
    padding: 5px 5px 5px 5px;
    margin: 0px 5px 0px 0px;
    top: 0;
    border-radius: 0px 0px 20px 20px;
    -webkit-mask-image: linear-gradient(to bottom, black calc(100% - 10px), transparent 100%);
    mask-image: linear-gradient(to bottom, black calc(100% - 10px), transparent 100%);
    transition: all 0.5s ease-in-out;
}
#springbok_chatbot_widget_shadow .activeWidget .main .anchor {
    margin-top: 5px;
}
#springbok_chatbot_widget_shadow .activeWidget .main .lineWrapper .imgMsg {
    flex-wrap: nowrap;
    gap: 5px;
    align-items: center;
    margin-bottom: 12px;
}
#springbok_chatbot_widget_shadow .activeWidget .main .lineWrapper .imgMsg img {
    width: 26px;
    height: 26px;
    border-radius: 100%;
}
#springbok_chatbot_widget_shadow .activeWidget .main .lineWrapper .userAviImg {
    height: 28px !important;
    width: 28px !important;
}
#springbok_chatbot_widget_shadow .activeWidget .main .line {
    padding: 10px;
    word-wrap: break-word;
    white-space: wrap;
    text-align: left;
}
#springbok_chatbot_widget_shadow .activeWidget .main .user {
    border-radius: 10px 10px 0 10px;
    align-items: flex-end;
    animation: initial-fade-in 0.5s ease-in 0s 1;
    transition: all 0.5s ease-in-out;
    margin-left: 10px;
}
#springbok_chatbot_widget_shadow .activeWidget .main .bot {
    border-radius: 10px 10px 10px 0;
    align-items: flex-start;
    margin-right: 10px;
    animation: initial-fade-in 0.5s ease-in 0s 1;
    transition: all 0.5s ease-in-out;
}
#springbok_chatbot_widget_shadow .activeWidget .main .bot.dots {
    animation: initial-fade-in 0.2s ease-in 0s 1;
}
#springbok_chatbot_widget_shadow .activeWidget .main .dots div:nth-child(2) {
    animation-delay: 0.2s;
}
#springbok_chatbot_widget_shadow .activeWidget .main .dots div:nth-child(3) {
    animation-delay: 0.4s;
}
#springbok_chatbot_widget_shadow .activeWidget .main .dots div {
    border-radius: 50%;
    background-color: gray;
    width: 10px;
    height: 10px;
    margin: 5px;
    animation: loader 0.6s infinite alternate;
}
@keyframes loader {
    to {
        opacity: 0.5;
        transform: translate(0, -5px);
        background: gray;
    }
}
#springbok_chatbot_widget_shadow .activeWidget .main [class^='readMoreButton'] {
    border: none;
    background-color: rgba(0, 0, 0, 0);
    text-decoration: underline;
    cursor: pointer;
    margin-top: 9px;
    font-size: 16px;
    padding: 0px;
}
#springbok_chatbot_widget_shadow .activeWidget .main [class^='readMoreButton']:hover {
    transition: all 0.2s ease-in-out;
    filter: brightness(110%);
    cursor: pointer;
}
#springbok_chatbot_widget_shadow .activeWidget .main .responseText hr {
    display: none;
}
#springbok_chatbot_widget_shadow .activeWidget .main .responseText p {
    margin: 0px;
    word-break: break-word;
    padding-bottom: 10px;
}
#springbok_chatbot_widget_shadow .activeWidget .main .responseText p:last-child {
    padding-bottom: 1px;
}
#springbok_chatbot_widget_shadow .activeWidget .main .buttonList {
    margin-top: 10px;
}
#springbok_chatbot_widget_shadow .activeWidget .main .buttonList .responseButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 12px;
    border: none;
    padding: 10px 8px;
    margin-top: 8px;
    transition: all 0.5s ease-in-out;
}
#springbok_chatbot_widget_shadow .activeWidget .main .buttonList .responseButton:hover {
    transition: all 0.2s ease-in-out;
    filter: brightness(110%);
    cursor: pointer;
}
#springbok_chatbot_widget_shadow .activeWidget .main .buttonList .responseButton .buttonImage {
    border-radius: 0px !important;
    height: 16px;
    width: 16px;
    margin-left: 10px;
}
#springbok_chatbot_widget_shadow .activeWidget .main .buttonList .responseButton .ascbuttonTextContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
#springbok_chatbot_widget_shadow .activeWidget .main .buttonList .responseButton .ascbuttonTextContainer .ascButtonText {
    font-size: 16px !important;
}
#springbok_chatbot_widget_shadow .activeWidget .main .buttonList .responseButton .ascbuttonTextContainer .ascButtonSubtext {
    padding-top: 5px;
    font-size: 13px !important;
}
#springbok_chatbot_widget_shadow .activeWidget .main .buttonList .responseButton .noSubtextPadding {
    height: 18px;
}
#springbok_chatbot_widget_shadow .activeWidget .main a .responseButton {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    border-radius: 12px;
    border: none;
    padding: 8px 4px;
    margin: 4px;
}
#springbok_chatbot_widget_shadow .activeWidget .main a .responseButton:hover {
    transition: all 0.2s ease-in-out;
    filter: brightness(105%);
    cursor: pointer;
}
#springbok_chatbot_widget_shadow .activeWidget .main a .responseButton .buttonImage {
    border-radius: 0px !important;
}
#springbok_chatbot_widget_shadow .activeWidget .main a .responseButton .buttonSubtext {
    font-size: x-small !important;
}
#springbok_chatbot_widget_shadow .activeWidget .main .responseImage {
    border-radius: 0 !important;
}
#springbok_chatbot_widget_shadow .activeWidget .main .responseVideo {
    padding: 0px;
}
#springbok_chatbot_widget_shadow .activeWidget .main .quick-link-holder {
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    justify-content: space-evenly;
}
#springbok_chatbot_widget_shadow .activeWidget .main .quick-link-holder a {
    text-decoration: none !important;
}
#springbok_chatbot_widget_shadow .activeWidget .main .quick-link-holder .quick-link-button {
    flex-direction: column;
    width: 60px;
    padding: 6px;
    border-radius: 10px;
    color: #fff;
    font-size: 12px !important;
    text-decoration: none !important;
}
#springbok_chatbot_widget_shadow .activeWidget .main .quick-link-holder .quick-link-button img {
    max-width: 20px;
    max-height: 20px;
    padding-top: 3px;
    border-radius: 0px;
}
#springbok_chatbot_widget_shadow .activeWidget .main .quick-link-holder .quick-link-button:hover {
    transition: all 0.2s ease-in-out;
    filter: brightness(105%);
}
#springbok_chatbot_widget_shadow .activeWidget .main .quick-link-holder .quick-link-button .quick-link-text {
    overflow-wrap: normal !important;
}
#springbok_chatbot_widget_shadow .activeWidget .main .fuzzySearch {
    margin-top: 6px;
    margin-bottom: 6px;
    width: 238px;
}
#springbok_chatbot_widget_shadow .activeWidget .main .fuzzySearch input {
    height: 36px;
    border-radius: 10px 10px 10px 10px;
    margin: 4px;
    box-shadow: none;
    background-color: #fff;
    font-size: 14px;
    width: 100%;
    font-family: inherit;
}
#springbok_chatbot_widget_shadow .activeWidget .main .fuzzySearch svg {
    display: none;
}
#springbok_chatbot_widget_shadow .activeWidget .main .fuzzySearch .line {
    height: 0px;
    padding: 0px !important;
}
#springbok_chatbot_widget_shadow .activeWidget .main .fuzzySearch ul {
    width: 100%;
    list-style-type: none;
    background-color: #fff;
    border-radius: 10px;
    font-size: 14px;
    box-shadow: none;
    margin: 0px;
    padding-bottom: 0px;
}
#springbok_chatbot_widget_shadow .activeWidget .main .fuzzySearch li {
    height: 30px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    border-radius: 5px;
}
#springbok_chatbot_widget_shadow .activeWidget .main .fuzzySearch li:hover {
    background-color: rgba(0, 0, 0, 0.1254901961);
    cursor: pointer;
}
#springbok_chatbot_widget_shadow .activeWidget .main .fuzzySearch li p {
    margin: 0px;
}
#springbok_chatbot_widget_shadow .activeWidget .main .fuzzySearch li p p {
    margin: 0px;
}
#springbok_chatbot_widget_shadow .activeWidget .scrollButton {
    position: absolute;
    right: 15px;
    height: 22px;
    width: 22px;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 50%;
    text-align: center;
    border: 2px solid rgba(0, 0, 0, 0.1882352941);
}
#springbok_chatbot_widget_shadow .activeWidget .scrollButton:hover {
    transition: all 0.2s ease-in-out;
    filter: brightness(95%);
    cursor: pointer;
}
#springbok_chatbot_widget_shadow .activeWidget .scrollButton:active {
    transition: all 0.2s ease-in-out;
    filter: brightness(90%);
}
#springbok_chatbot_widget_shadow .activeWidget .scrollButton img {
    width: 20px;
    height: 20px;
}
#springbok_chatbot_widget_shadow .activeWidget .main.scrollbarButtons .scrollButton {
    visibility: visible;
}
#springbok_chatbot_widget_shadow .activeWidget .main.scrollbarButtons .scrollButton.up {
    top: 110px;
}
#springbok_chatbot_widget_shadow .activeWidget .main.scrollbarButtons .scrollButton.down {
    bottom: 68px;
    padding-top: 1px;
}
@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
    }
    35% {
        box-shadow: 0 0 0 25px rgba(0, 0, 0, 0);
    }
    50% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}
@keyframes initial-fade-in {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 0.2;
    }
    40% {
        opacity: 0.4;
    }
    60% {
        opacity: 0.6;
    }
    80% {
        opacity: 0.8;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fade-out {
    0% {
        opacity: 1;
    }
    20% {
        opacity: 0.8;
    }
    40% {
        opacity: 0.6;
    }
    60% {
        opacity: 0.4;
    }
    80% {
        opacity: 0.2;
    }
    100% {
        opacity: 0;
    }
}
@keyframes scrollIn {
    0% {
        transform: scaleY(0);
        transform-origin: top;
    }
    100% {
        transform: scaleY(1);
        transform-origin: top;
    }
}
@keyframes scrollOut {
    0% {
        transform: scaleY(1);
        transform-origin: top;
    }
    100% {
        transform: scaleY(0);
        transform-origin: top;
    }
} /*# sourceMappingURL=widget.min.css.map */
