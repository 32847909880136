@font-face {
  font-family: Inter;
  src: url("Inter.0704e0a5.ttf") format("truetype");
}

#springbok_chatbot_widget_shadow {
  all: initial;
  z-index: 2000000 !important;
  position: fixed !important;
}

#springbok_chatbot_widget_shadow * {
  box-sizing: content-box;
  font-family: Inter, sans-serif;
}

#springbok_chatbot_widget_shadow ::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

#springbok_chatbot_widget_shadow ::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, .03);
  border-radius: 10px;
}

#springbok_chatbot_widget_shadow ::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, .125);
  border-radius: 10px;
}

#springbok_chatbot_widget_shadow ::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, .19);
}

#springbok_chatbot_widget_shadow :root {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, .125);
}

#springbok_chatbot_widget_shadow button:hover {
  -webkit-filter: brightness(110%);
  filter: brightness(110%);
  transition: all .2s ease-in-out;
}

#springbok_chatbot_widget_shadow .hidden {
  display: none;
}

#springbok_chatbot_widget_shadow .flexC {
  flex-direction: column;
  display: flex;
}

#springbok_chatbot_widget_shadow .flexR {
  flex-direction: row;
  display: flex;
}

#springbok_chatbot_widget_shadow .inactiveWidgetPopup {
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  width: 200px;
  text-align: left;
  cursor: pointer;
  border: 3px solid;
  border-radius: 10px 10px 0;
  padding: 10px 18px 10px 10px;
  transition: all .25s linear 2s;
  -webkit-animation: initial-fade-in 1s ease-in, pulse 4s linear 5s infinite;
  animation: initial-fade-in 1s ease-in, pulse 4s linear 5s infinite;
  position: fixed;
  bottom: 85px;
  right: 85px;
  font-size: 14px !important;
}

#springbok_chatbot_widget_shadow .inactiveWidgetPopup .inactiveWidgetPopupClose {
  width: 16px;
  height: 16px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  position: absolute;
  top: 3px;
  right: 3px;
}

@media only screen and (max-width: 720px) {
  #springbok_chatbot_widget_shadow .inactiveWidgetPopup {
    width: 200px;
    border: 3px solid;
    border-radius: 10px 10px 0;
    padding: 10px 18px 10px 10px;
    position: fixed;
    bottom: 61px;
    right: 61px;
    font-size: 14px !important;
  }

  #springbok_chatbot_widget_shadow .inactiveWidgetPopup img {
    height: 100%;
    width: 100%;
    border-radius: 15px;
  }

  #springbok_chatbot_widget_shadow .inactiveWidgetPopup img:hover {
    -webkit-filter: brightness(150%);
    filter: brightness(150%);
    cursor: pointer;
  }
}

#springbok_chatbot_widget_shadow .inactiveWidgetPopup.show {
  display: flex;
}

#springbok_chatbot_widget_shadow .inactiveWidgetPopup.hide {
  display: none;
}

#springbok_chatbot_widget_shadow .inactiveWidgetButton {
  height: 80px;
  width: 80px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  padding: 0;
  -webkit-animation: initial-fade-in 1s ease-in;
  animation: initial-fade-in 1s ease-in;
  display: flex;
  position: fixed;
  bottom: 10px;
  right: 10px;
}

#springbok_chatbot_widget_shadow .inactiveWidgetButton img {
  height: 82px;
  width: 82px;
  border-radius: 100%;
}

#springbok_chatbot_widget_shadow .inactiveWidgetButton img:hover {
  -webkit-filter: brightness(130%);
  filter: brightness(130%);
  cursor: pointer;
  transition: all .2s ease-in-out;
}

@media only screen and (max-width: 720px) {
  #springbok_chatbot_widget_shadow .inactiveWidgetButton {
    height: 60px;
    width: 60px;
    border: none;
    border-radius: 100px;
    padding: 0;
    position: fixed;
    bottom: 5px;
    right: 5px;
  }

  #springbok_chatbot_widget_shadow .inactiveWidgetButton img {
    height: 100%;
    width: 100%;
    border-radius: 100px;
  }

  #springbok_chatbot_widget_shadow .inactiveWidgetButton img:hover {
    -webkit-filter: brightness(130%);
    filter: brightness(130%);
    cursor: pointer;
    transition: all .2s ease-in-out;
  }
}

#springbok_chatbot_widget_shadow .scrollButton {
  visibility: collapse;
}

#springbok_chatbot_widget_shadow .activeWidget.fullScreen {
  min-width: 50vw;
  min-height: 80vh;
  transition: all .5s ease-in-out;
}

#springbok_chatbot_widget_shadow .activeWidget.mobile {
  width: 100%;
  height: 100dvh;
  border-radius: 0;
  position: fixed;
  top: 0;
  left: 0;
}

#springbok_chatbot_widget_shadow .activeWidget {
  min-width: 0;
  min-height: 0;
  border: none;
  border-radius: 20px;
  flex-direction: column;
  transition: all .5s ease-in-out;
  -webkit-animation: initial-fade-in .5s ease-in;
  animation: initial-fade-in .5s ease-in;
  display: flex;
  position: fixed;
  bottom: 10px;
  right: 10px;
  box-shadow: 0 0 1px rgba(0, 0, 0, .16), 0 0 5px rgba(0, 0, 0, .59);
}

#springbok_chatbot_widget_shadow .activeWidget iframe {
  max-width: 95%;
  border: none;
  transition: all .5s ease-in-out;
}

#springbok_chatbot_widget_shadow .activeWidget .titleHolder {
  height: 20px;
  z-index: 2;
  border-bottom: 1px solid rgba(0, 0, 0, .08);
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
  -webkit-animation: initial-fade-in .5s ease-in;
  animation: initial-fade-in .5s ease-in;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  box-shadow: 0 3px 5px -3px rgba(0, 0, 0, .08);
}

#springbok_chatbot_widget_shadow .activeWidget .titleHolder .backButton {
  width: 18px;
  height: 18px;
  max-height: 32px;
  cursor: pointer;
  padding: 3px 12px;
  -webkit-animation: initial-fade-in .5s ease-in;
  animation: initial-fade-in .5s ease-in;
}

#springbok_chatbot_widget_shadow .activeWidget .titleHolder .title {
  margin-left: 5px;
  font-weight: 600;
  -webkit-animation: initial-fade-in .5s ease-in;
  animation: initial-fade-in .5s ease-in;
  font-size: 16px !important;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut {
  -webkit-animation: fade-out .5s ease-out;
  animation: fade-out .5s ease-out;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay {
  -webkit-animation: initial-fade-in .5s ease-in;
  animation: initial-fade-in .5s ease-in;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut {
  z-index: 9999;
  height: calc(100% - 56px);
  width: 100%;
  background-color: rgba(83, 83, 83, .9);
  border-radius: 0 0 20px 20px;
  transition: all .5s ease-in-out;
  position: absolute;
  top: 56px;
  right: 0;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget.widgetMenu, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget.widgetMenu {
  -webkit-animation: initial-fade-in .5s ease-in;
  animation: initial-fade-in .5s ease-in;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget {
  text-align: center;
  justify-content: space-around;
  transition: all .5s ease-in-out;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer {
  height: 80%;
  flex-direction: column;
  justify-content: center;
  font-weight: 300;
  transition: all .5s ease-in-out;
  display: flex;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedbackButtons, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedbackButtons {
  max-width: 100%;
  flex-direction: row;
  justify-content: center;
  margin: 20px;
  display: flex;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedbackButtons button, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedbackButtons button {
  cursor: pointer;
  max-width: 120px;
  border: none;
  border-radius: 5px;
  margin: 0 7px;
  padding: 15px 20px;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form {
  -webkit-animation: initial-fade-in .5s ease-in;
  animation: initial-fade-in .5s ease-in;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form-fade-out, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form-fade-out {
  -webkit-animation: fade-out .5s ease-out;
  animation: fade-out .5s ease-out;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form-fade-out, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form-fade-out {
  z-index: 10000;
  position: inherit;
  cursor: default;
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  transition: all .5s ease-in-out;
  -webkit-animation: initial-fade-in .5s ease-in;
  animation: initial-fade-in .5s ease-in;
  display: flex;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form .feedbackButtons, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form-fade-out .feedbackButtons, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form .feedbackButtons, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form-fade-out .feedbackButtons {
  flex-direction: row;
  justify-content: space-around;
  display: flex;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form h3, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form-fade-out h3, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form h3, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form-fade-out h3 {
  margin: 0;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form button, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form-fade-out button, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form button, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form-fade-out button {
  cursor: pointer;
  max-width: 120px;
  border: none;
  border-radius: 5px;
  margin: 0 20px;
  padding: 15px 20px;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form .feedback-emojis, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form-fade-out .feedback-emojis, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form .feedback-emojis, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form-fade-out .feedback-emojis {
  width: 300px;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: x-large;
  display: flex;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form .feedback-emojis span, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form-fade-out .feedback-emojis span, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form .feedback-emojis span, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form-fade-out .feedback-emojis span {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form .feedback-emojis span div, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form-fade-out .feedback-emojis span div, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form .feedback-emojis span div, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form-fade-out .feedback-emojis span div {
  font-size: 14px;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form .feedback-emojis span:hover, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form-fade-out .feedback-emojis span:hover, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form .feedback-emojis span:hover, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form-fade-out .feedback-emojis span:hover {
  -webkit-filter: brightness(120%);
  filter: brightness(120%);
  transition: all .2s ease-in-out;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form .feedback-text, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form-fade-out .feedback-text, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form .feedback-text, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form-fade-out .feedback-text {
  text-align: center;
  flex-direction: column;
  -webkit-animation: initial-fade-in .5s ease-in;
  animation: initial-fade-in .5s ease-in;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form .feedback-text textarea, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form-fade-out .feedback-text textarea, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form .feedback-text textarea, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form-fade-out .feedback-text textarea {
  resize: none;
  width: 75%;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid;
  border-radius: 5px;
  margin: 10px;
  padding: 10px;
}

@media only screen and (max-width: 720px) {
  #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form .feedback-text textarea, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .closeWidget .feedbackContainer .feedback-form-fade-out .feedback-text textarea, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form .feedback-text textarea, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .closeWidget .feedbackContainer .feedback-form-fade-out .feedback-text textarea {
    font-size: 16px !important;
  }
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut {
  -webkit-animation: scrollOut .5s ease-out;
  animation: scrollOut .5s ease-out;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu {
  -webkit-animation: scrollIn .5s ease-in;
  animation: scrollIn .5s ease-in;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut {
  z-index: 10000;
  width: calc(100% - 30px);
  border-radius: 0 0 20px 20px;
  flex-direction: column;
  padding: 8px 15px 0;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu div, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut div, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu div, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut div {
  padding: 5px;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form {
  -webkit-animation: initial-fade-in .5s ease-in;
  animation: initial-fade-in .5s ease-in;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form-fade-out, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form-fade-out, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form-fade-out, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form-fade-out {
  -webkit-animation: fade-out .5s ease-out;
  animation: fade-out .5s ease-out;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form-fade-out, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form-fade-out, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form-fade-out, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form-fade-out {
  cursor: default;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  font-weight: 300;
  transition: all .5s ease-in-out;
  display: flex;
}

@media only screen and (max-width: 720px) {
  #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form-fade-out, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form-fade-out, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form-fade-out, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form-fade-out {
    padding-left: 20px;
  }
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form h3, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form-fade-out h3, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form h3, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form-fade-out h3, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form h3, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form-fade-out h3, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form h3, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form-fade-out h3 {
  margin: 0;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form button, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form-fade-out button, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form button, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form-fade-out button, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form button, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form-fade-out button, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form button, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form-fade-out button {
  cursor: pointer;
  max-width: 120px;
  border: none;
  border-radius: 5px;
  margin: 0 20px;
  padding: 15px 20px;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form .feedback-emojis, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form-fade-out .feedback-emojis, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form .feedback-emojis, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form-fade-out .feedback-emojis, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form .feedback-emojis, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form-fade-out .feedback-emojis, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form .feedback-emojis, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form-fade-out .feedback-emojis {
  width: 300px;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: x-large;
  display: flex;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form .feedback-emojis span, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form-fade-out .feedback-emojis span, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form .feedback-emojis span, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form-fade-out .feedback-emojis span, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form .feedback-emojis span, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form-fade-out .feedback-emojis span, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form .feedback-emojis span, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form-fade-out .feedback-emojis span {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form .feedback-emojis span div, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form-fade-out .feedback-emojis span div, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form .feedback-emojis span div, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form-fade-out .feedback-emojis span div, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form .feedback-emojis span div, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form-fade-out .feedback-emojis span div, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form .feedback-emojis span div, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form-fade-out .feedback-emojis span div {
  font-size: 14px;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form .feedback-emojis span:hover, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form-fade-out .feedback-emojis span:hover, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form .feedback-emojis span:hover, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form-fade-out .feedback-emojis span:hover, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form .feedback-emojis span:hover, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form-fade-out .feedback-emojis span:hover, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form .feedback-emojis span:hover, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form-fade-out .feedback-emojis span:hover {
  -webkit-filter: brightness(120%);
  filter: brightness(120%);
  transition: all .2s ease-in-out;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form .feedback-text, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form-fade-out .feedback-text, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form .feedback-text, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form-fade-out .feedback-text, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form .feedback-text, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form-fade-out .feedback-text, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form .feedback-text, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form-fade-out .feedback-text {
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: all .5s ease-in-out;
  -webkit-animation: initial-fade-in .5s ease-in;
  animation: initial-fade-in .5s ease-in;
  display: flex;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form .feedback-text textarea, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form-fade-out .feedback-text textarea, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form .feedback-text textarea, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form-fade-out .feedback-text textarea, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form .feedback-text textarea, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form-fade-out .feedback-text textarea, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form .feedback-text textarea, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form-fade-out .feedback-text textarea {
  resize: none;
  width: 75%;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid;
  border-radius: 5px;
  margin: 10px;
  padding: 10px;
}

@media only screen and (max-width: 720px) {
  #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form .feedback-text textarea, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .feedback-form-fade-out .feedback-text textarea, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form .feedback-text textarea, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .feedback-form-fade-out .feedback-text textarea, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form .feedback-text textarea, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .feedback-form-fade-out .feedback-text textarea, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form .feedback-text textarea, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .feedback-form-fade-out .feedback-text textarea {
    font-size: 16px !important;
  }
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuHeader, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuHeader, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuHeader, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuHeader {
  border-bottom: 2px solid rgba(0, 0, 0, .03);
  flex-direction: row;
  justify-content: left;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  display: flex;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuHeader .widgetMenuCloseBtn, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuHeader .widgetMenuCloseBtn, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuHeader .widgetMenuCloseBtn, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuHeader .widgetMenuCloseBtn {
  background: none;
  border: none;
  position: absolute;
  right: 15px;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuHeader .widgetMenuCloseBtn :hover, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuHeader .widgetMenuCloseBtn :hover, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuHeader .widgetMenuCloseBtn :hover, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuHeader .widgetMenuCloseBtn :hover {
  cursor: pointer;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuHeader svg, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuHeader svg, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuHeader svg, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuHeader svg {
  width: 20px;
  height: 20px;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuOptions, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuOptions, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuOptions, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuOptions {
  margin-top: 4px;
  margin-bottom: 4px;
  overflow-y: auto;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuOptions div, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuOptions div, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuOptions div, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuOptions div {
  flex-direction: row;
  align-items: center;
  display: flex;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuOptions .widgetMenuOptImg, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuOptions .widgetMenuOptImg, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuOptions .widgetMenuOptImg, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuOptions .widgetMenuOptImg {
  height: 20px;
  width: 20px;
  cursor: pointer;
  margin-right: 15px;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuOptions input, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuOptions input, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuOptions input, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuOptions input {
  margin-left: 35px;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuOptions .sendEmailBtn, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuOptions .sendEmailBtn, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuOptions .sendEmailBtn, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuOptions .sendEmailBtn {
  margin-left: 5px;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuOptions .widgetMenuOpt, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuOptions .widgetMenuOpt, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuOptions .widgetMenuOpt, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuOptions .widgetMenuOpt {
  cursor: pointer;
  margin: 5px 0;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuOptions .widgetMenuOpt:hover, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuOptions .widgetMenuOpt:hover, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuOptions .widgetMenuOpt:hover, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuOptions .widgetMenuOpt:hover {
  background-color: rgba(0, 0, 0, .03);
  border-radius: 5px;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuEmailSent, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuEmailSent, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuEmailSent, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuEmailSent {
  padding-left: 40px;
  font-style: italic;
  font-weight: normal;
  -webkit-animation: initial-fade-in .5s ease-in;
  animation: initial-fade-in .5s ease-in;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuEmailForm, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuEmailForm, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuEmailForm, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuEmailForm {
  -webkit-animation: initial-fade-in .5s ease-in;
  animation: initial-fade-in .5s ease-in;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuEmailForm input, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuEmailForm input, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuEmailForm input, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuEmailForm input {
  height: 20px;
  font-size: 16px;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuEmailForm button, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuEmailForm button, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuEmailForm button, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuEmailForm button {
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: none;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuFooter, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuFooter, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuFooter, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuFooter {
  justify-content: left;
  align-items: left;
  border-top: 2px solid rgba(0, 0, 0, .03);
  flex-direction: column;
  font-size: 12px;
  font-weight: bold;
  display: flex;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuFooter a, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuFooter a, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuFooter a, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuFooter a {
  color: #000;
  cursor: pointer;
  font-weight: normal;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuFooter a :visited, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuFooter a :visited, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuFooter a :visited, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuFooter a :visited, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuFooter a :hover, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuFooter a :hover, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuFooter a :hover, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuFooter a :hover {
  color: #000;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuFooter .widgetMenuPB, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuFooter .widgetMenuPB, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuFooter .widgetMenuPB, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuFooter .widgetMenuPB {
  flex-direction: row;
  justify-content: center;
  padding-top: 20px;
  display: flex;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenu .widgetMenuFooter .widgetMenuPB img, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlay .widgetMenufadeOut .widgetMenuFooter .widgetMenuPB img, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenu .widgetMenuFooter .widgetMenuPB img, #springbok_chatbot_widget_shadow .activeWidget .widgetMenuOverlayfadeOut .widgetMenufadeOut .widgetMenuFooter .widgetMenuPB img {
  max-height: 40px;
  margin-left: 5px;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetHeader.mobile {
  border-radius: 0;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetHeader {
  height: 60px;
  z-index: 11;
  border-radius: 20px 20px 0 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  font-size: 20px;
  transition: all .5s ease-in-out;
  display: flex;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetHeader img {
  max-height: 40px;
  max-width: 40px;
  border-radius: 30px;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetHeader .title {
  overflow-wrap: normal;
  text-align: center;
  color: #fff;
  font-weight: bold;
  font-size: 18px !important;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetHeader .buttonContainer {
  flex-direction: row;
  align-items: center;
  display: flex;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetHeader button {
  background-color: #05c3cf;
  border: none;
  border-radius: 5px;
  margin: 3px;
  padding: 0;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetHeader button:hover {
  -webkit-filter: brightness(120%);
  filter: brightness(120%);
  cursor: pointer;
  transition: all .2s ease-in-out;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetHeader .customHeaderButton {
  height: 30px;
  width: 50px;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetHeader .buttonUnavailable:hover {
  cursor: wait;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetHeader .ellipsis {
  height: 24px;
  width: 24px;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetHeader .refreshChat, #springbok_chatbot_widget_shadow .activeWidget .widgetHeader .closeButton, #springbok_chatbot_widget_shadow .activeWidget .widgetHeader .minimiseButton, #springbok_chatbot_widget_shadow .activeWidget .widgetHeader .ellipsis {
  margin: 1px 0 1px 4px;
  -webkit-animation: initial-fade-in .5s ease-in;
  animation: initial-fade-in .5s ease-in;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetHeader .refreshChat:hover, #springbok_chatbot_widget_shadow .activeWidget .widgetHeader .closeButton:hover, #springbok_chatbot_widget_shadow .activeWidget .widgetHeader .minimiseButton:hover, #springbok_chatbot_widget_shadow .activeWidget .widgetHeader .ellipsis:hover {
  -webkit-filter: brightness(120%);
  filter: brightness(120%);
  cursor: pointer;
  transition: all .2s ease-in-out;
}

#springbok_chatbot_widget_shadow .activeWidget .widgetHeader .refreshChat, #springbok_chatbot_widget_shadow .activeWidget .widgetHeader .minimiseButton, #springbok_chatbot_widget_shadow .activeWidget .widgetHeader .closeButton {
  height: 22px;
  width: 22px;
}

#springbok_chatbot_widget_shadow .activeWidget .coverPageHolder {
  z-index: 10;
  background-color: #fff;
  border-radius: 0 0 20px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  transition: all .5s ease-in-out;
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
}

#springbok_chatbot_widget_shadow .activeWidget .coverPageHolder .coverPageHeader {
  flex-direction: row;
  justify-content: center;
  display: flex;
}

#springbok_chatbot_widget_shadow .activeWidget .coverPageHolder .coverPageText {
  text-align: center;
  max-width: 400px;
  flex-direction: row;
  justify-content: center;
  padding: 10px;
  display: flex;
  font-size: 14px !important;
}

#springbok_chatbot_widget_shadow .activeWidget .coverPageHolder .coverPageSubheader {
  flex-direction: row;
  margin-top: 10%;
  padding-left: 10%;
  display: flex;
  font-size: 14px !important;
}

#springbok_chatbot_widget_shadow .activeWidget .coverPageHolder .coverPageDropdown {
  width: 80%;
  flex-direction: column;
  margin: 5px 10% 10%;
  display: flex;
  font-size: 14px !important;
}

#springbok_chatbot_widget_shadow .activeWidget .coverPageHolder .coverPageButtonContainer {
  flex-direction: row;
  justify-content: center;
  display: flex;
}

#springbok_chatbot_widget_shadow .activeWidget .coverPageHolder .coverPageButtonContainer .coverPageSubmitButton {
  border: none;
  border-radius: 10px;
  margin: 10px;
  padding: 7px 50px;
  font-size: 14px !important;
}

#springbok_chatbot_widget_shadow .activeWidget .coverPageHolder .coverPageButtonContainer .coverPageSubmitButton:hover {
  cursor: pointer;
  -webkit-filter: brightness(110%);
  filter: brightness(110%);
  transition: all .2s ease-in-out;
}

#springbok_chatbot_widget_shadow .activeWidget .coverPageHolder .coverPageImgHolder {
  flex-direction: row;
  justify-content: center;
  display: flex;
}

#springbok_chatbot_widget_shadow .activeWidget .coverPageHolder .coverPageImgHolder .coverPageImg {
  max-width: 200px;
  min-width: 100px;
  margin: 10px;
}

#springbok_chatbot_widget_shadow .activeWidget .userInput {
  transition: all .5s ease-in-out;
  -webkit-animation: initial-fade-in .5s ease-in;
  animation: initial-fade-in .5s ease-in;
}

#springbok_chatbot_widget_shadow .activeWidget .userInput .inputArea {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: 10px;
  flex-wrap: nowrap;
  align-items: center;
  margin: 4px 10px 8px;
}

#springbok_chatbot_widget_shadow .activeWidget .userInput .inputArea textarea {
  max-height: 26px;
  resize: none;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  flex: 1;
  margin: 5px 40px 5px 10px;
  padding: 5px 0;
}

@media only screen and (max-width: 720px) {
  #springbok_chatbot_widget_shadow .activeWidget .userInput .inputArea textarea {
    font-size: 16px !important;
  }
}

#springbok_chatbot_widget_shadow .activeWidget .userInput .inputArea svg {
  width: 25px;
  height: 25px;
  cursor: pointer;
  padding: 15px 10px;
  position: absolute;
  bottom: 7px;
  right: 10px;
}

#springbok_chatbot_widget_shadow .activeWidget .main {
  border-radius: 0 0 20px 20px;
  margin: 0 5px 0 0;
  padding: 5px;
  transition: all .5s ease-in-out;
  top: 0;
  overflow-x: hidden;
  mask-image: linear-gradient(#000 calc(100% - 10px), rgba(0, 0, 0, 0) 100%);
}

#springbok_chatbot_widget_shadow .activeWidget .main .anchor {
  margin-top: 5px;
}

#springbok_chatbot_widget_shadow .activeWidget .main .lineWrapper .imgMsg {
  flex-wrap: nowrap;
  align-items: center;
  gap: 5px;
  margin-bottom: 12px;
}

#springbok_chatbot_widget_shadow .activeWidget .main .lineWrapper .imgMsg img {
  width: 26px;
  height: 26px;
  border-radius: 100%;
}

#springbok_chatbot_widget_shadow .activeWidget .main .lineWrapper .userAviImg {
  height: 28px !important;
  width: 28px !important;
}

#springbok_chatbot_widget_shadow .activeWidget .main .line {
  word-wrap: break-word;
  white-space: wrap;
  text-align: left;
  padding: 10px;
}

#springbok_chatbot_widget_shadow .activeWidget .main .user {
  border-radius: 10px 10px 0;
  align-items: flex-end;
  margin-left: 10px;
  transition: all .5s ease-in-out;
  -webkit-animation: initial-fade-in .5s ease-in;
  animation: initial-fade-in .5s ease-in;
}

#springbok_chatbot_widget_shadow .activeWidget .main .bot {
  border-radius: 10px 10px 10px 0;
  align-items: flex-start;
  margin-right: 10px;
  transition: all .5s ease-in-out;
  -webkit-animation: initial-fade-in .5s ease-in;
  animation: initial-fade-in .5s ease-in;
}

#springbok_chatbot_widget_shadow .activeWidget .main .bot.dots {
  -webkit-animation: initial-fade-in .2s ease-in;
  animation: initial-fade-in .2s ease-in;
}

#springbok_chatbot_widget_shadow .activeWidget .main .dots div:nth-child(2) {
  -webkit-animation-delay: .2s;
  animation-delay: .2s;
}

#springbok_chatbot_widget_shadow .activeWidget .main .dots div:nth-child(3) {
  -webkit-animation-delay: .4s;
  animation-delay: .4s;
}

#springbok_chatbot_widget_shadow .activeWidget .main .dots div {
  width: 10px;
  height: 10px;
  background-color: gray;
  border-radius: 50%;
  margin: 5px;
  -webkit-animation: loader .6s infinite alternate;
  animation: loader .6s infinite alternate;
}

@-webkit-keyframes loader {
  to {
    opacity: .5;
    background: gray;
    -webkit-transform: translate(0, -5px);
    transform: translate(0, -5px);
  }
}

@keyframes loader {
  to {
    opacity: .5;
    background: gray;
    -webkit-transform: translate(0, -5px);
    transform: translate(0, -5px);
  }
}

#springbok_chatbot_widget_shadow .activeWidget .main [class^="readMoreButton"] {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  margin-top: 9px;
  padding: 0;
  font-size: 16px;
  text-decoration: underline;
}

#springbok_chatbot_widget_shadow .activeWidget .main [class^="readMoreButton"]:hover {
  -webkit-filter: brightness(110%);
  filter: brightness(110%);
  cursor: pointer;
  transition: all .2s ease-in-out;
}

#springbok_chatbot_widget_shadow .activeWidget .main .responseText hr {
  display: none;
}

#springbok_chatbot_widget_shadow .activeWidget .main .responseText p {
  word-break: break-word;
  margin: 0;
  padding-bottom: 10px;
}

#springbok_chatbot_widget_shadow .activeWidget .main .responseText p:last-child {
  padding-bottom: 1px;
}

#springbok_chatbot_widget_shadow .activeWidget .main .buttonList {
  margin-top: 10px;
}

#springbok_chatbot_widget_shadow .activeWidget .main .buttonList .responseButton {
  border: none;
  border-radius: 12px;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
  padding: 10px 8px;
  transition: all .5s ease-in-out;
  display: flex;
}

#springbok_chatbot_widget_shadow .activeWidget .main .buttonList .responseButton:hover {
  -webkit-filter: brightness(110%);
  filter: brightness(110%);
  cursor: pointer;
  transition: all .2s ease-in-out;
}

#springbok_chatbot_widget_shadow .activeWidget .main .buttonList .responseButton .buttonImage {
  height: 16px;
  width: 16px;
  margin-left: 10px;
  border-radius: 0 !important;
}

#springbok_chatbot_widget_shadow .activeWidget .main .buttonList .responseButton .ascbuttonTextContainer {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

#springbok_chatbot_widget_shadow .activeWidget .main .buttonList .responseButton .ascbuttonTextContainer .ascButtonText {
  font-size: 16px !important;
}

#springbok_chatbot_widget_shadow .activeWidget .main .buttonList .responseButton .ascbuttonTextContainer .ascButtonSubtext {
  padding-top: 5px;
  font-size: 13px !important;
}

#springbok_chatbot_widget_shadow .activeWidget .main .buttonList .responseButton .noSubtextPadding {
  height: 18px;
}

#springbok_chatbot_widget_shadow .activeWidget .main a .responseButton {
  color: #fff;
  border: none;
  border-radius: 12px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 4px;
  padding: 8px 4px;
  display: flex;
}

#springbok_chatbot_widget_shadow .activeWidget .main a .responseButton:hover {
  -webkit-filter: brightness(105%);
  filter: brightness(105%);
  cursor: pointer;
  transition: all .2s ease-in-out;
}

#springbok_chatbot_widget_shadow .activeWidget .main a .responseButton .buttonImage {
  border-radius: 0 !important;
}

#springbok_chatbot_widget_shadow .activeWidget .main a .responseButton .buttonSubtext {
  font-size: x-small !important;
}

#springbok_chatbot_widget_shadow .activeWidget .main .responseImage {
  border-radius: 0 !important;
}

#springbok_chatbot_widget_shadow .activeWidget .main .responseVideo {
  padding: 0;
}

#springbok_chatbot_widget_shadow .activeWidget .main .quick-link-holder {
  text-align: center;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
}

#springbok_chatbot_widget_shadow .activeWidget .main .quick-link-holder a {
  text-decoration: none !important;
}

#springbok_chatbot_widget_shadow .activeWidget .main .quick-link-holder .quick-link-button {
  width: 60px;
  color: #fff;
  border-radius: 10px;
  flex-direction: column;
  padding: 6px;
  font-size: 12px !important;
  text-decoration: none !important;
}

#springbok_chatbot_widget_shadow .activeWidget .main .quick-link-holder .quick-link-button img {
  max-width: 20px;
  max-height: 20px;
  border-radius: 0;
  padding-top: 3px;
}

#springbok_chatbot_widget_shadow .activeWidget .main .quick-link-holder .quick-link-button:hover {
  -webkit-filter: brightness(105%);
  filter: brightness(105%);
  transition: all .2s ease-in-out;
}

#springbok_chatbot_widget_shadow .activeWidget .main .quick-link-holder .quick-link-button .quick-link-text {
  overflow-wrap: normal !important;
}

#springbok_chatbot_widget_shadow .activeWidget .main .fuzzySearch {
  width: 238px;
  margin-top: 6px;
  margin-bottom: 6px;
}

#springbok_chatbot_widget_shadow .activeWidget .main .fuzzySearch input {
  height: 36px;
  box-shadow: none;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  margin: 4px;
  font-family: inherit;
  font-size: 14px;
}

#springbok_chatbot_widget_shadow .activeWidget .main .fuzzySearch svg {
  display: none;
}

#springbok_chatbot_widget_shadow .activeWidget .main .fuzzySearch .line {
  height: 0;
  padding: 0 !important;
}

#springbok_chatbot_widget_shadow .activeWidget .main .fuzzySearch ul {
  width: 100%;
  box-shadow: none;
  background-color: #fff;
  border-radius: 10px;
  margin: 0;
  padding-bottom: 0;
  font-size: 14px;
  list-style-type: none;
}

#springbok_chatbot_widget_shadow .activeWidget .main .fuzzySearch li {
  height: 30px;
  border-radius: 5px;
  align-items: center;
  padding-left: 10px;
  display: flex;
}

#springbok_chatbot_widget_shadow .activeWidget .main .fuzzySearch li:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, .125);
}

#springbok_chatbot_widget_shadow .activeWidget .main .fuzzySearch li p, #springbok_chatbot_widget_shadow .activeWidget .main .fuzzySearch li p p {
  margin: 0;
}

#springbok_chatbot_widget_shadow .activeWidget .scrollButton {
  height: 22px;
  width: 22px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  border: 2px solid rgba(0, 0, 0, .19);
  border-radius: 50%;
  position: absolute;
  right: 15px;
}

#springbok_chatbot_widget_shadow .activeWidget .scrollButton:hover {
  -webkit-filter: brightness(95%);
  filter: brightness(95%);
  cursor: pointer;
  transition: all .2s ease-in-out;
}

#springbok_chatbot_widget_shadow .activeWidget .scrollButton:active {
  -webkit-filter: brightness(90%);
  filter: brightness(90%);
  transition: all .2s ease-in-out;
}

#springbok_chatbot_widget_shadow .activeWidget .scrollButton img {
  width: 20px;
  height: 20px;
}

#springbok_chatbot_widget_shadow .activeWidget .main.scrollbarButtons .scrollButton {
  visibility: visible;
}

#springbok_chatbot_widget_shadow .activeWidget .main.scrollbarButtons .scrollButton.up {
  top: 110px;
}

#springbok_chatbot_widget_shadow .activeWidget .main.scrollbarButtons .scrollButton.down {
  padding-top: 1px;
  bottom: 68px;
}

@-webkit-keyframes pulse {
  0% {
    box-shadow: 0 0 rgba(0, 0, 0, .4);
  }

  35% {
    box-shadow: 0 0 0 25px rgba(0, 0, 0, 0);
  }

  50% {
    box-shadow: 0 0 rgba(0, 0, 0, 0);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 rgba(0, 0, 0, .4);
  }

  35% {
    box-shadow: 0 0 0 25px rgba(0, 0, 0, 0);
  }

  50% {
    box-shadow: 0 0 rgba(0, 0, 0, 0);
  }
}

@-webkit-keyframes initial-fade-in {
  0% {
    opacity: 0;
  }

  20% {
    opacity: .2;
  }

  40% {
    opacity: .4;
  }

  60% {
    opacity: .6;
  }

  80% {
    opacity: .8;
  }

  100% {
    opacity: 1;
  }
}

@keyframes initial-fade-in {
  0% {
    opacity: 0;
  }

  20% {
    opacity: .2;
  }

  40% {
    opacity: .4;
  }

  60% {
    opacity: .6;
  }

  80% {
    opacity: .8;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }

  20% {
    opacity: .8;
  }

  40% {
    opacity: .6;
  }

  60% {
    opacity: .4;
  }

  80% {
    opacity: .2;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  20% {
    opacity: .8;
  }

  40% {
    opacity: .6;
  }

  60% {
    opacity: .4;
  }

  80% {
    opacity: .2;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes scrollIn {
  0% {
    -webkit-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }

  100% {
    -webkit-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}

@keyframes scrollIn {
  0% {
    -webkit-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }

  100% {
    -webkit-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}

@-webkit-keyframes scrollOut {
  0% {
    -webkit-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }

  100% {
    -webkit-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
}

@keyframes scrollOut {
  0% {
    -webkit-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }

  100% {
    -webkit-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
}

/*# sourceMappingURL=index.css.map */
